import { useState } from 'react'
import Modal from '../Modal'
import MobileModal from '../home/MobileModal'

export default function Header() {
    const [openMobile, setOpenMobile] = useState(false)
    return (
        <header className="sticky top-0 z-50 w-full bg-white/80 backdrop-blur-sm">
            <Modal open={openMobile} setOpen={setOpenMobile}>
                <MobileModal setOpen={setOpenMobile} />
            </Modal>
            <div className='px-5 sm:px-[50px] max-w-[1440px] mx-auto flex justify-between items-center py-3 gap-2 sm:gap-0'>
                <div>
                    <a href='/'><img src='/logo.webp' alt='Logo' className='flex-shrink-0 w-[100px] sm:w-[120px]'/></a>
                </div>
                <div className='flex gap-2 sm:gap-5 items-center'>
                    <a href="https://admin.amarhr.mn/register" className='flex items-center justify-center text-base sm:text-lg bg-primary text-white rounded-[5px] px-2.5 sm:px-[19px] py-1.5 leading-[18px] sm:leading-[22px] sm:w-[244px] shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary/90 tracking-[1%] transition-colors duration-300'>
                        <span className='hidden sm:block'>10 хоног үнэгүй турших</span>
                        <span className="sm:hidden">Турших</span>
                    </a>
                    <a href="https://admin.amarhr.mn/login" className="hidden sm:block text-center rounded-[5px] sm:w-[130px] text-base sm:text-lg bg-white px-2.5 sm:px-[19px] py-[5px] leading-[18px] sm:leading-[22px] text-primary border-[1px] border-primary shadow-sm hover:bg-primary hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary transition-colors duration-300" >
                        Нэвтрэх
                    </a>
                    <button onClick={() => setOpenMobile(true)} className="block sm:hidden text-center rounded-[5px] sm:w-[130px] text-base sm:text-lg bg-white px-2.5 sm:px-[19px] py-[5px] leading-[18px] sm:leading-[22px] text-primary border-[1px] border-primary shadow-sm hover:bg-primary hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary transition-colors duration-300">
                        Нэвтрэх
                    </button>
                </div>
            </div>
        </header>
    )
}