import { motion } from 'framer-motion'

export default function TermMain(){
    return (
        <div className="h-screen w-full px-5 lg:px-[50px] max-w-[1440px] mx-auto flex flex-col items-center justify-center pt-[100px] pb-[50px] sm:pb-[100px] gap-[30px] sm:gap-[60px]">
            <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className="flex flex-col gap-[5px] text-[26px] sm:text-5xl text-center text-primary leading-8 sm:leading-[58px] tracking-[2%]">Нууцлалын бодлого</motion.h1>
            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className='flex-1 w-full max-w-3xl rounded-md overflow-hidden'>
                <iframe className="h-[80vh] w-full" src="https://docs.google.com/document/d/1z-fUxMGGt0ZJjAlL88I1yVQ--RXVGL59wPEYm25y5jo/pub?embedded=true"></iframe>
            </motion.div>
        </div>
    )
}