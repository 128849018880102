export const sendAPIRequest = async (email) => {
    let headers = {"Content-Type": "application/json"}
    let fetchOptions = { method: 'POST', headers: headers, body: JSON.stringify({email}), cache: 'no-store' }
    let res
    try {
        res = await fetch(process.env.REACT_APP_API_URL + '/mail/contact/api-request', fetchOptions)
        if(!res.ok) throw new Error('Error sending API request')
    } catch(err) {
        console.error('Error sending API request: ', err)
        throw err
    }
    const data = await res.json()
    if(!data) return false
    return data
}

export const sendAddonRequest = async (email, question) => {
    let headers = {"Content-Type": "application/json"}
    let fetchOptions = { method: 'POST', headers: headers, body: JSON.stringify({ email, question }), cache: 'no-store' }
    let res
    try {
        res = await fetch(process.env.REACT_APP_API_URL + '/mail/contact/addon-request', fetchOptions)
        if(!res.ok) throw new Error('Error sending addon request')
    } catch(err) {
        console.error('Error sending addon request: ', err)
        throw err
    }
    const data = await res.json()
    if(!data) return false
    return data
}

export const sendContactRequest = async (name, email, question) => {
    let headers = {"Content-Type": "application/json"}
    let fetchOptions = { method: 'POST', headers: headers, body: JSON.stringify({ name, email, question }), cache: 'no-store' }
    let res
    try {
        res = await fetch(process.env.REACT_APP_API_URL + '/mail/contact/contact-request', fetchOptions)
        if(!res.ok) throw new Error('Error sending contact request')
    } catch(err) {
        console.error('Error sending contact request: ', err)
        throw err
    }
    const data = await res.json()
    if(!data) return false
    return data
}

