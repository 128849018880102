import { useState, useEffect } from 'react'
import { toast } from 'sonner'
import { motion } from 'framer-motion'
import { Dialog, DialogBackdrop, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronLeft, Info, Smartphone, ChevronDown, ChevronUp, Settings, Smile } from "lucide-react"

import LogoIcon from '../../assets/logo-icon.svg'
import { sendContactRequest } from '../../utils/request'
import { isValidEmail, isValidName } from '../../utils/util'

const companies = [
    {name: 'DZ', logo: "/dz.webp"},
    {name: 'BusinessMN', logo: "/businessmn.webp"},
    {name: 'Artisan', logo: "/artisan.webp"},
    {name: 'LPS', logo: "/lps.webp"},
]

const settings = [
    { id: 'setting1', title: "Үнэлгээ, олговрын тохиргоо", desc: ["Нэмэгдэл олговрын үнэлгээг байгууллагын дотоод журмын дагуу хялбар тохируулна", "Үйл ажиллагааны онцлогоос хамаарч 3 хүртэлх нэмэгдэл/олговор шинээр үүсгэх боломжтой", "Нийтээр нь тохируулсан үнэлгээ олговрыг ажилтан тус бүрээр өөрчлөх боломжтой"], modal: '/settings1-open.webp', icon: '/sal1-icon.svg', href: '#' },
    { id: 'setting2', title: "Дундаж цалин хөлсний тохиргоо", desc: ["Ажилчдын цалинг үндсэн болон дундаж цалин хөлснөөс бодох сонголттой", "Дундаж цалин хөлсөнд тооцох олговрыг сонгож оруулах боломжтой"], modal: '/settings2-open.webp', icon: '/sal2-icon.svg', href: '#' },
    { id: 'setting3', title: "Алба хэлтсийн тохиргоо", desc: "Хэдэн ч алба хэлтэс үүсгэж болох ба алба хэлтэс бүрийн ажиллах нөхцөл цалингийн мөчлөг гэх мэт цалин бодолтод хамаарах тохиргоог ялгаатай тохируулах боломжтой", modal: '/settings3-open.webp', icon: '/sal3-icon.svg', href: '#' },
]

const features = [
    { id: 'sal1', title: "Цалин хөлсний тохиргоо", desc: ["Цалинг үндсэн болон дундаж цалин хөлснөөс бодох сонголттой", "Дундаж цалин хөлсөнд тооцох олговрыг сонгож оруулах боломжтой"], icon: '/salary1-icon.svg', href: '#' },
    { id: 'sal2', title: "Үнэлгээ, олговрын тохиргоо", desc: ["Нэмэгдэл олговрын үнэлгээг байгууллагын дотоод журмын дагуу хялбар оруулна", "Нийтээр нь тохируулсан үнэлгээ олговрыг ажилтан тус бүрээр өөрчлөх боломжтой"], icon: '/salary2-icon.svg', href: '#' },
    { id: 'sal3', title: "Алба хэлтсийн тохиргоо", desc: ["Алба хэлтэс бүрийн цалингийн мөчлөг ажиллах нөхцлийг ялгаатай тохируулах боломжтой", "Алба хэлтсийн тохиргоог ажилтан тус бүрээр өөрчлөх боломжтой"], icon: '/salary3-icon.svg', href: '#' },
    { id: 'sal4', title: "Ажилчдын цалинг автоматаар бодно", desc: "Цаг бүртгэл, Цалин тохиргооны дагуу ажилчдын цалинг автоматаар бодно", icon: '/salary4-icon.svg', href: '#' },
    { id: 'sal5', title: "Ээлжийн амралтын олговор тооцоолно", desc: "Ажилтны ээлжийн амралтын олговрыг хоногт нь тохируулан автоматаар тооцоолно", icon: '/salary5-icon.svg', href: '#' },
    { id: 'sal6', title: "Нэмэгдэл суутгалыг цалин тооцоололд хялбар оруулна", desc: ["Ажилчдын цалин тооцоололд нэмэгдэл суутгалыг алба, хэлтсээр нь сонгон оруулна", "Нийтээр нь оруулсан нэмэгдэл суутгалыг ажилтан тус бүрээр засах, хасах боломжтой"], icon: '/salary6-icon.svg', href: '#' },
    { id: 'sal7', title: "Цалин олговортой холбоотой бүх төрлийн тайлан гаргана", desc: "НДШ, ХХОАТ, Цалингийн тайлан гаргана Цалингийн багц гүйлгээний хүснэгт үүсгэнэ", icon: '/salary7-icon.svg', href: '#' },
]

const faqs = [
    { question: "Цаг бүртгэх 2 сонголттой гэж юу вэ?", answer: "Байршлаар болон байгууллагын Wi-Fi холболтоор цаг бүртгэх сонголттой" },
    { question: "Нэмэгдэл/олговрын сонголттой гэж юу вэ?", answer: "Цаг бүртгэх байршил тус бүр дээр ялгаатай нэмэгдэл/олговор сонгон тооцох боломжтой" },
    { question: "Уян хатан тохиргоотой гэж юу вэ?", answer: "Хэдэн ч алба хэлтэс үүсгэж болох ба алба хэлтэс бүрийн онцлогт тохирсон цаг бүртгэлийн тохиргоо хийх боломжтой" },
    { question: "Ажилтны апп-тай уялдаатай гэж юу вэ?", answer: "Цаг бүртгэлтэй холбоотой бүх модуль ажилтны апп-тай уялдаж ажиллана" },
    { question: "Ирцийн тайлан гаргана гэж юу вэ?", answer: "Нийт ажилчдын ирцийн тайлан excel файлаар гаргана" },
    { question: "Засвар, өөрчлөлтийг хялбар хийнэ гэж юу вэ?", answer: "Бүртгэгдсэн цаг засах, ажлын хуваарь, ээлж солих, нэмэгдэл нэмж хасах үйлдлүүдийг хялбар гүйцэтгэнэ" },
    { question: "Цалин тооцоололтой уялдаж ажиллана гэж юу вэ?", answer: "Цалин тооцоолотой уялдаж ажиллан ажилтны цалинг автоматаар бодно" },
]

export default function SalaryMain(){
    const [openSettingModal, setOpenSettingModal] = useState(false)
    const [openSalaryAppModal, setOpenSalaryAppModal] = useState(false)
    const [selectedSetting, setSelectedSetting] = useState(null)
    const [open, setOpen] = useState(false)
    const [allOpen, setAllOpen] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [question, setQuestion] = useState('')
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [buttonText, setButtonText] = useState('Илгээх')

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') handleContactRequest()
    }

    const handleContactRequest = async () => {
        if(!name) return toast.error('Нэр хоосон байна')
        if(!email) return toast.error('Имэйл хаяг хоосон байна')
        if(!question) return toast.error('Асуулт хоосон байна')
        if(!isValidName(name)) return toast.error('Нэр буруу байна')
        if(!isValidEmail(email)) return toast.error('Имэйл хаяг буруу байна')
        setLoading(true)
        try {
            const res = await sendContactRequest(name, email, question)
            if(!res) throw new Error('Асуултыг илгээхэд алдаа гарлаа')
            toast.success('Асуулт амжилттай илгээгдлээ')
            setIsButtonDisabled(true)
            setButtonText('Илгээгдсэн')
        } catch (error) {
            toast.error('Асуултыг илгээхэд алдаа гарлаа')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <motion.header initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="sticky top-0 z-50 backdrop-blur-sm py-4">
                <a href='/' className="px-5 lg:px-[50px] max-w-[1440px] mx-auto flex gap-2 items-center">
                    <ChevronLeft className="w-6 h-6" />
                    <span className='text-base sm:text-xl font-semibold leading-6 sm:leading-7 tracking-[1%]'>Буцах</span>
                </a>
            </motion.header>

            <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto flex flex-col items-center justify-center pt-[100px] sm:pt-[115px] pb-[50px] sm:pb-[100px] gap-[15px] sm:gap-[30px] relative">
                <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                    <div style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)', }} className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-accent to-[#5788e3] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"/>
                </div>
                <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className="flex flex-col gap-[5px] text-[26px] sm:text-5xl text-center text-primary leading-8 sm:leading-[58px] tracking-[2%]"><span className='w-full text-center md:text-left text-base sm:text-xl text-[#0C5DD5] leading-6 sm:leading-7 tracking-[1%]'>Цаг бүртгэл, Цалин тохиргооны дагуу</span>Ажилчдын цалинг автоматаар бодно</motion.h1>
                <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="text-base sm:text-xl text-primary leading-6 sm:leading-7 tracking-[1%]">Энгийн хэрнээ мэргэжлийн</motion.p>
            </div>

            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className='px-5 lg:px-[50px] max-w-[1440px] mx-auto relative overflow-hidden'>
                <img src='/salary-main.webp' className="w-[768px] sm:w-full max-w-none h-full mt-25 mb-15" alt="salary-main" />
            </motion.div>

            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="px-5 lg:px-[50px] max-w-[1440px] mx-auto py-[60px] flex flex-col sm:flex-row justify-start items-center gap-[30px] sm:gap-4">
                <div className='flex justify-start items-start'>
                    <h1 className="text-base sm:text-lg text-primary !leading-[22px] tracking-[2%] text-center sm:text-start sm:w-[180px]">Манай хэрэглэгчид нэмэгдсээр байна</h1>
                </div>
                
                <div className='logos w-full'>
                    <div className='logos-mobile-slide flex sm:hidden'>
                        { companies.map((company, index) => (
                            <img src={company.logo} key={index} alt={company.name} className="w-auto h-full object-cover" />
                        )) }
                    </div>
                    <div className='logos-slide-register hidden sm:flex'>
                        { companies.map((company, index) => (
                            <img src={company.logo} key={index} alt={company.name} className="w-auto h-full object-cover" />
                        )) }
                    </div>
                </div>
            </motion.div> 

            <div className="py-[150px] sm:py-[180px]">
                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pb-[100px] sm:pb-[200px]">
                    <div className="flex flex-col justify-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="hidden sm:block text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цалин тооцооллын уян хатан <br/>тохиргоотой</motion.h1>
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="block sm:hidden text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цалин тооцооллын уян хатан тохиргоотой</motion.h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-20 gap-[60px]">
                        { settings.map((setting, index) => (
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 * (index + 1), duration: 0.4 }} viewport={{ once: true }} key={index} className='bg-white rounded-lg flex flex-col'>
                                <div>
                                    <div className="flex flex-col gap-[5px] justify-center items-start">
                                        <div className='flex justify-center gap-[15px]'>
                                            <img src={setting.icon} className="w-10 h-10" alt={setting.title} />
                                        </div>
                                        <span className="text-lg text-primary font-semibold leading-6 sm:leading-7 tracking-[1%]">{setting.title}</span>
                                    </div>
                                    <div className="mt-[30px] text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%] space-y-5">
                                        {Array.isArray(setting.desc) ? (
                                            setting.desc.map((descItem, i) => (
                                                <p key={i}>{descItem}</p>
                                            ))
                                        ) : (
                                            <p>{setting.desc}</p>
                                        )}
                                    </div>
                                </div>
                                <span onClick={() => {setSelectedSetting(setting); setOpenSettingModal(true)}} className="text-lg pt-10 text-primary font-semibold leading-[22px] gap-[5px] tracking-[1%] flex flex-row items-center cursor-pointer">
                                    <Settings className='w-5 h-5'/><span aria-hidden="true">Тохиргоог харах</span>
                                </span>
                            </motion.div>
                        )) }
                    </div>
                    <Dialog open={openSettingModal} onClose={() => setOpenSettingModal(false)} className="relative z-50">
                        <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />
                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex flex-col min-h-full justify-center text-center items-center sm:p-0">
                                <DialogPanel transition className="relative w-full transform overflow-hidden text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-6xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 flex items-center justify-center" >
                                    {selectedSetting && (
                                        <img src={selectedSetting.modal} className="max-w-[90%] max-h-[80vh] object-contain" alt={selectedSetting.title} />
                                    )}
                                </DialogPanel>
                                <div onClick={() => setOpenSettingModal(false)} className='text-white font-semibold cursor-pointer rounded-xl border-2 border-white py-2 px-4 mt-4'>
                                    Цонх хаах
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-x-20 sm:gap-x-[100px] gap-y-[30px] sm:gap-y-0">
                    <div className="flex flex-col justify-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Тайлан 2 секундэд</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-10 block max-w-lg sm:max-w-[533px] text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%] space-y-5">
                            <p>НДШ, ХХОАТ-н тайлан тайлагнахад бэлэн excel файл үүсгэнэ</p>
                            <p>Цалингийн тайлан гаргана/excel форматаар</p>
                            <p>Цалингийн багц гүйлгээний хүснэгтийг банк тус бүрийн excel загвараар үүсгэнэ</p>
                        </motion.div>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="flex flex-col gap-[30px]">
                            <div className="mt-[70px] flex gap-2.5 justify-start items-center">
                                <img src='/excel.svg' alt='excel' className='w-8 h-8' />
                                <a href='https://docs.google.com/spreadsheets/d/1bGhJpVOWSzvvMDc6iCYGIyDJ3aStliq_i3o6_ex5xGw/' target="_blank" className='text-primary font-semibold text-lg sm:text-xl leading-6 sm:leading-7 tracking-[1%] hover:text-primary/90 transition-colors duration-300'>Загвар харах</a>
                            </div>
                            <div className="flex flex-row flex-wrap text-nowrap gap-[30px] gap-y-[20px] text-primary font-semibold text-base sm:text-lg leading-[22px] tracking-[1%]">
                                <p>НДШ тайлан</p>
                                <p>ХХОАТ тайлан</p>
                                <p>Цалингийн тайлан</p>
                                <p>Цалингийн багц гүйлгээний хүснэгт</p>
                            </div>
                        </motion.div>
                    </div>
                    <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center">
                        <img src='/salary1.webp' className="w-full mx-auto" alt="salary1" />
                    </motion.div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto overflow-hidden grid grid-cols-1 sm:grid-cols-2 gap-20 sm:gap-[100px] py-[150px] sm:pt-[200px] sm:pb-[180px]">
                    <motion.div initial={{ opacity: 0, x: 40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center">
                        <img src='/salary2.webp' className="w-full mx-auto" alt="salary2" />
                    </motion.div>
                    <div className="flex flex-col justify-center order-first sm:order-last">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:max-w-[384px] sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Нэмэгдэл суутгалыг алба хэлтсээр нь</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-[30px] sm:max-w-[538px] block max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%]">
                            <p>Сар бүр тогтмол бодогддоггүй нэмэгдэл суутгалыг алба хэлтсээр нь сонгон тухайн сарын цалин тооцоололд хялбар оруулна</p>
                        </motion.div>
                        <div className='flex gap-2 sm:max-w-[538px] flex-row border-dashed border-[1px] border-accent rounded-[10px] p-2.5 w-full mt-10'>
                            <Info className="h-7 w-7 text-[#18AA0B] flex-shrink-0" />
                            <motion.h2 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="font-normal text-base sm:text-lg text-primary leading-6 sm:leading-7 tracking-[2%]">Нийтээр нь оруулсан нэмэгдэл суутгалыг ажилтан тус бүрээр нь засах, хасах өөрчлөлт хийх боломжтой</motion.h2>
                        </div>
                    </div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-20 sm:gap-[100px]">
                    <div className="flex flex-col justify-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Ээлжийн амралтын төвөггүй тооцоолол</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-10 block max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%] space-y-5">
                            <p>Ээлжийн амралтын хугацаа дөхсөн ажилчдыг сануулахаас гадна ажилтнаас ирүүлсэн ээлжийн амралтын олговор авах хүсэлтийг автоматаар бодно</p>
                        </motion.div>
                    </div>
                    <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center">
                        <img src='/salary3.webp' className="w-full mx-auto" alt="salary3" />
                    </motion.div>
                </div>

                <div className='bg-background mt-[60px] flex items-center justify-center'>
                    <div className="overflow-hidden">
                        <div className="mx-auto gap-y-[30px] lg:max-w-[1072px]">
                            <div className="lg:mx-auto flex items-center">
                                <div className='px-5'>
                                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className=" mt-[45px] text-base leading-[18px] text-primary tracking-[1%]">
                                        <p>Цалингийн задаргаа цонхноос</p>
                                    </motion.div>
                                    <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className=" mt-[5px] text-lg sm:text-2xl text-primary leading-7 sm:leading-[34px] tracking-[1%]">Ээлжийн амралтын олговрыг хялбар бодно</motion.p>
                                </div>
                            </div>
                            <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="px-5 flex items-center mt-[30px] mb-[60px] relative">
                                <img src='/salary4.webp' alt="salary4" className="max-w-none w-full h-auto" />
                            </motion.div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden sm:gap-[100px] py-[150px] sm:py-[300px]">
                    <div className='px-5 lg:px-[50px] max-w-[1440px] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-20 relative isolate'>
                        <div className="lg:max-w-[556px] flex flex-col justify-center">
                            <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цэгцтэй, ойлгомжтой<br/>Цалингийн задаргаа цонхтой</motion.h1>
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-10 block text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%] space-y-5">
                                <p>Цаг бүртгэл, Цалин тохиргооны дагуу ажилтны цалингийн задаргаа тогтмол шинэчлэгдэнэ / Real time</p>
                                <p>Алба хэлтсээр нь оруулсан нэмэгдэл суутгалыг зөвхөн тухайн ажилтанд тохируулан засах мөн шинээр нэмэгдэл суутгал үүсгэх боломжтой</p>
                            </motion.div>
                            <div className='flex gap-[25px] sm:max-w-[543px] flex-col border-dashed border-[1px] border-accent rounded-[10px] p-2.5 w-full mt-[55px]'>
                                <div className='flex gap-2 justify-start items-start'>
                                    <Smile className="h-7 w-7 text-[#FF00D6] flex-shrink-0" />
                                    <motion.h2 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="font-normal text-base sm:text-lg text-primary leading-6 sm:leading-7 tracking-[1%]">Ажилтан өөрийн цалингийн задаргааг ажилтны апп-н цалингийн задаргаа цэсээс харах боломжтой<br/>Энэ нь ажилтнаас ирэх цалин тооцооллын асуултыг багасгаж итгэлцлийг нэмнэ</motion.h2>
                                </div>
                                <div className='flex gap-[3px] items-center ml-8'>
                                    <Smartphone className='w-[22px] h-[22px]'/>
                                    <p onClick={() => setOpenSalaryAppModal(true)} className='font-semibold text-primary text-base sm:text-lg cursor-pointer leading-6 sm:leading-[22px] tracking-[1%]'>Ажилтны апп-с харъя</p>
                                </div>
                            </div>
                        </div>
                        <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center relative">
                            <img src='/salary5.webp' className="w-full lg:w-[700px] -left-0 -bottom-0 h-auto max-w-none lg:absolute" alt="salary5" />
                        </motion.div>
                        <Dialog open={openSalaryAppModal} onClose={() => setOpenSalaryAppModal(false)} className="relative z-50">
                            <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex flex-col min-h-full justify-center text-center items-center sm:p-0">
                                    <DialogPanel transition className="relative w-full transform overflow-hidden text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-6xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 flex items-center justify-center" >
                                        <img src='/salary5-open.webp' className="max-w-[90%] max-h-[80vh] object-contain" alt="salary5-mobile" />
                                    </DialogPanel>

                                    <div onClick={() => setOpenSalaryAppModal(false)} className='text-white font-semibold cursor-pointer rounded-xl border-2 border-white py-2 px-4 mt-4'>
                                        Цонх хаах
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[50px] pb-[100px] sm:pb-[210px]">
                    <div className="flex flex-col justify-center items-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цалин тооцооллын хураангуй</motion.h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-20 gap-[40px]">
                        { features.map((feature, index) => (
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 * (index + 1), duration: 0.4 }} viewport={{ once: true }} key={index} className='bg-white rounded-lg flex flex-col'>
                                <div className='py-[5px]'>
                                    <div className="flex flex-col gap-5 justify-center items-start">
                                        <div className='flex justify-center gap-[15px]'>
                                            <img src={feature.icon} className="w-10 h-10" alt={feature.title} />
                                            {feature.extraIcon ? <img src={feature.extraIcon} className="w-10 h-10" alt={feature.title} /> : null}
                                        </div>
                                        <span className="text-lg sm:text-[22px] text-primary font-semibold leading-6 sm:leading-7 tracking-[1%]">{feature.title}</span>
                                    </div>
                                    <div className="mt-[15px] text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%] space-y-[15px]">
                                        {Array.isArray(feature.desc) ? (
                                            feature.desc.map((descItem, i) => (
                                                <p key={i}>{descItem}</p>
                                            ))
                                        ) : (
                                            <p>{feature.desc}</p>
                                        )}
                                    </div>
                                </div>
                            </motion.div>
                        )) }
                    </div>
                </div>

                <div className='px-5 lg:px-[50px] max-w-[1440px] mx-auto'>
                    <div className="flex flex-col items-center justify-center py-[65px] gap-[20px] border-[1px] border-indicator/10 shadow-sm rounded-[20px] relative overflow-hidden">
                        <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                            <div style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)', }} className="relative left-[calc(85%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-accent to-[#5788e3] opacity-30 sm:left-[calc(85%-30rem)] sm:w-full"/>
                        </div>
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className="flex flex-col gap-[5px] text-3xl sm:text-[38px] text-center text-primary leading-8 sm:leading-[48px]"><span className='w-full text-center md:text-left text-base text-[#0C5DD5] leading-5 sm:leading-6 tracking-[2%]'>Цалин тооцоололтой уялдаж ажиллах</span>Цаг бүртгэлийн амар системийг</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 30 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="flex flex-col gap-y-6 sm:items-start gap-x-6 justify-start">
                            <a href="https://admin.amarhr.mn/register" className='w-full sm:w-[244px] flex justify-center items-center text-base sm:text-lg bg-primary text-white font-semibold rounded-[5px] px-[19px] py-1.5 leading-[18px] sm:leading-[22px] shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary/90 tracking-[1%]'>
                                10 хоног үнэгүй турших
                            </a>
                        </motion.div>
                    </div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[100px] pb-[150px] sm:pt-[210px] sm:pb-[250px]">
                    <div className="flex flex-col justify-center items-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">
                            Түгээмэл асуулт, хариулт
                        </motion.h1>
                    </div>
                    <Disclosure as="div" className="mt-[60px] max-w-2xl mx-auto">
                        {({ open }) => (
                            <>
                                <DisclosureButton as="div" className="flex w-full justify-end px-5 pb-[15px]" onClick={() => setAllOpen(!allOpen)}>
                                    <motion.div className="flex items-center" initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                                        <span className="text-base sm:text-lg text-primary font-semibold leading-[22px]">
                                            {allOpen ? "Бүгдийг нуух" : "Бүгдийг харах"}
                                        </span>
                                        <span className="ml-6 flex h-7 items-center text-primary">
                                            {allOpen ? (
                                                <ChevronUp className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                            ) : (
                                                <ChevronDown className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                            )}
                                        </span>
                                    </motion.div>
                                </DisclosureButton>
                                <motion.dl className="divide-y divide-main/15" initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                                    {faqs.map((faq) => (
                                        <Disclosure as="div" key={faq.question} className="p-5">
                                            {({ open }) => {
                                                if(open === true) setAllOpen(false)
                                                return (
                                                    <>
                                                        <div>
                                                            <DisclosureButton className="flex w-full items-center justify-between text-left text-slate-700">
                                                                <span className="text-base sm:text-lg text-primary font-semibold leading-[22px]">
                                                                    {faq.question}
                                                                </span>
                                                                <span className="ml-6 flex h-7 items-center text-primary">
                                                                    { (open || allOpen) ? (
                                                                        <ChevronUp className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                                                    ) : (
                                                                        <ChevronDown className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                                                    )}
                                                                </span>
                                                            </DisclosureButton>
                                                        </div>
                                                        { (open || allOpen) && (
                                                            <div className="mt-[18px] pr-2">
                                                                <p className="text-base sm:text-lg leading-6 sm:leading-7 text-primary/75" dangerouslySetInnerHTML={{__html: faq.answer }}></p>
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            }}
                                        </Disclosure>
                                    ))}
                                </motion.dl>
                            </>
                        )}
                    </Disclosure>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto">
                    <div className="flex flex-col items-center justify-center text-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Бид танд тусалъя</motion.h1>
                        <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="mt-10 text-base sm:text-lg text-primary leading-7 tracking-[1%]">Танд цаг бүртгэлэй холбоотой асуулт байвал бидэн рүү илгээгээрэй<br/>Бид таны асуултад шуурхай хариулах болно</motion.p>
                    </div>
                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="flex flex-col gap-4 max-w-3xl mx-auto w-full mt-20">
                        <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
                            <div className="w-full sm:w-1/2">
                                <span className="text-base sm:text-lg text-primary/75 leading-6">
                                    Таны нэр
                                </span>
                                <div className="mt-2">
                                    <input id="name" name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} autoComplete='given-name' className="input w-full" />
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2">
                                <span className="text-base sm:text-lg text-primary/75 leading-6">
                                    Хариулт авах и-мэйл хаяг
                                </span>
                                <div className="mt-2">
                                    <input id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='email' className="input w-full" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <textarea id="question" name="question" type="text" value={question} onChange={(e) => setQuestion(e.target.value)} onKeyDown={handleKeyDown} placeholder="Сонирхсон асуултаа энд бичнэ үү" className="input w-full" rows={6} />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-y-4 sm:justify-between items-center">
                            <span className="text-sm text-center text-primary/75 font-normal leading-5 tracking-[1%]">
                                Бид таны и-мэйлийг бусадтай хуваалцахгүй, сурталчилгаанд ашиглахгүй
                            </span>
                            { loading ? (
                                <button type="button" className="w-fit flex justify-center rounded-[5px] bg-modal px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm cursor-not-allowed" >
                                    Уншиж байна..
                                </button>
                            ) : (
                                <button type="button" onClick={handleContactRequest} disabled={isButtonDisabled} className="w-fit flex justify-center items-center rounded-[5px] bg-modal px-[71px] py-2.5 h-[42px] text-base sm:text-lg font-semibold text-white leading-[22px] tracking-[1%] shadow-sm hover:bg-modal/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-modal/90 transition-colors duration-300">
                                    {buttonText}
                                </button>
                            )}
                        </div>
                    </motion.div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[150px] sm:pt-[200px]">
                    <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="flex flex-col items-center justify-center">
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                            <img src={LogoIcon} alt="Logo" className="w-20 h-20" />
                        </motion.div>
                        <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7 }} viewport={{ once: true }} className="mt-[15px] sm:mt-[25px] text-[#14153B] text-center text-3xl sm:text-[38px] leading-10 sm:leading-[48px] tracking-[2%]">AMARHR системийн Цалин тооцоолол<br/>танилцсанд баярлалаа</motion.p>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.9 }} viewport={{ once: true }} className="mt-[30px]">
                            <a href='/' className="flex gap-2 items-center">
                                <ChevronLeft className="w-6 h-6" />
                                <span className='text-base sm:text-xl font-semibold leading-6 sm:leading-7 tracking-[1%]'>Буцах</span>
                            </a>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}